@import '../../App.scss';
.subscribe {
  background-image: url('../../images/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  .subscribe_cover {
    width: 100%;
    height: 100%;
    background-color: $dark-blue-degrade;
    padding: 25px;
    .subscribe_container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      padding: 50px;
      .icon {
        // margin-top: 95px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: $gold 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: $gold;
          width: 35px;
          height: 36px;
          font-weight: 100;
        }
      }
      p {
        color: $white;
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
        line-height: 1.5;
      }
      .input {
        display: flex;
        flex-direction: row;
        align-items: center;

        input {
          border-radius: 4px 0 0 4px;
          border: none;
          background-color: rgba(255, 255, 255, 0.6);
          font-size: 17px;
          font-weight: 300;
          height: 55px;
          padding-left: 25px;
          &::placeholder {
            color: $text-black;
          }
          &:focus {
            outline: none;
          }
        }
        button {
          border-radius: 0 4px 4px 0;
          background-color: #bfac7e;
          color: #7f7f7f;
          font-size: 1.2em;
          height: 55px;
          top: 0px;
          right: 0px;
          padding: 0 30px;
          margin: 0;
          border: none;

          &:hover {
            background-color: $dark-ocean-blue;
            transition: all 0.4s;
            color: $white;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .subscribe {
    background-attachment: scroll;
    .subscribe_cover {
      .subscribe_container {
        padding: 25px;
        .input {
          width: 100%;
          flex-direction: column;
          gap: 10px;
          input {
            border-radius: 4px;
            width: 80%;
            margin-bottom: 10px;
          }
          button {
            border-radius: 4px;
            width: 80%;
            padding: 0;
          }
        }
      }
    }
  }
}
