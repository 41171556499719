@import '../../App.scss';

.survey {
  .survey_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .subtitle {
      margin: 50px 50px 0 50px;
      @media (max-width: 768px) {
        margin: 50px 0 0 0;
      }
      h4 {
        font-size: 30px;
        text-align: center;
        color: $grey-text;
        margin-bottom: 10px;
        @media (max-width: 768px) {
          font-size: 24px;
          width: 100%;
        }
      }
      p {
        font-size: 18px;
        text-align: center;
        color: $grey-text;
        margin-bottom: 10px;
        @media (max-width: 768px) {
          font-size: 15px;
          width: 100%;
        }
	b {
	    font-weight: 600;
	}
      }
    }
    .text_container {
      margin: 50px 20px 0 20px;
      max-width: 500px;
      background-color: $dark-blue;
      padding: 25px;
      color: $white;
      @media (max-width: 768px) {
        width: 90%;
      }

      h4 {
        font-size: 30px;
        text-align: center;
        color: $white;
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid $white;
      }
      ul {
        padding-left: 20px;
        li {
          font-size: 16px;
          font-weight: 400;
          margin-top: 10px;
        }
      }

      @media (max-width: 768px) {
        margin-top: 30px;
        padding: 20px;
        max-width: 100%;
      }

      @media (max-width: 480px) {
        margin-top: 20px;
        padding: 15px;
        max-width: 100%;
      }

      p {
        font-size: 18px;
        font-weight: 400;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-top: 40px;
      margin-bottom: 40px;
      width: 100%;
      max-width: 500px;
      padding: 20px;
      @media (max-width: 768px) {
        gap: 10px;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      @media (max-width: 480px) {
        gap: 5px;
        margin: 20px;
        margin-bottom: 20px;
      }

      .form_item {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        margin-bottom: 10px;

        input {
          padding: 10px;
          border-radius: 0;
          font-size: 16px;
          font-weight: 400;
          border: 1px solid $grey-text;
          color: $grey-text;
          @media (max-width: 768px) {
            font-size: 14px;
          }
          &:focus {
            outline: none;
            box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -webkit-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -moz-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
          }
        }
        .error {
          color: red;
          font-weight: 400;
          font-size: 14px;

          @media (max-width: 768px) {
            font-size: 12px;
          }

          @media (max-width: 480px) {
            font-size: 10px;
          }
        }

        p {
          color: $grey-text;
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 5px;
          @media (max-width: 768px) {
            font-size: 16px;
          }
          &.open_sans {
            color: $grey-text;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10px;
            @media (max-width: 768px) {
              font-size: 14px;
            }
          }
        }

        textarea {
          padding: 10px;
          border-radius: 0;
          font-size: 16px;
          font-weight: 400;
          border: 1px solid $grey-text;
          color: $grey-text;

          @media (max-width: 768px) {
            font-size: 14px;
          }

          @media (max-width: 480px) {
            font-size: 14px;
          }

          &:focus {
            outline: none;
            box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -webkit-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -moz-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
          }

          &::placeholder {
            color: $grey-text;
            font-weight: 400;
            font-size: 14px;
          }
        }

        select {
          padding: 10px;
          border-radius: 0;
          font-size: 16px;
          font-weight: 200;
          border: 1px solid $grey-text;
          color: $grey-text;
          font-weight: 400;

          @media (max-width: 768px) {
            font-size: 14px;
          }

          @media (max-width: 480px) {
            font-size: 14px;
          }

          &:focus {
            outline: none;
            box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -webkit-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -moz-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
          }
        }
      }

      button {
        background-color: $gold;
        color: $white;
        font-weight: 700;
        font-size: 17px;
        margin-right: 10px;
        line-height: 20px;
        padding: 15px 35px;
        height: 50px;
        border: 2px solid $gold;
        border-radius: 100px;
        cursor: pointer;

        @media (max-width: 768px) {
          font-size: 15px;
          padding: 12px 30px;
          height: 45px;
        }

        @media (max-width: 480px) {
          font-size: 13px;
          padding: 10px 25px;
          height: 40px;
        }

        &:hover {
          background-color: transparent;
          transition: all 0.4s;
          color: $gold;
        }
      }
    }
  }
}
