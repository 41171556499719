@import '../../App.scss';

.platform_top {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  width: 100%;
  .platform_container {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    max-width: 1170px;
    justify-content: center;
    overflow: hidden;
    .left {
      width: 50%; // Establece la anchura para pantallas medianas y grandes
      padding-left: 50px; // Margen izquierdo para pantallas medianas y grandes
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 30px; // Ajustado tamaño de fuente para pantallas más pequeñas
        font-weight: 500;
        color: $grey-text;
        margin-bottom: 25px; // Ajustado margen en pantallas más pequeñas
        margin-top: 20px;
      }

      .platform_item {
        display: flex;
        flex-direction: row;
        align-items: flex-start; // Centra verticalmente los elementos
        margin-bottom: 20px; // Ajustado margen en pantallas más pequeñas
        .text_container {
          margin-left: 10px;
          width: 90%; // Ocupa toda la anchura disponible

          h4 {
            font-weight: 500;
            font-size: 20px;
            color: $grey-text;
            margin-bottom: 10px;
            line-height: 1.5; // Ajustado line-height para mejorar la legibilidad
          }

          p {
            // width: 100%;
            font-weight: 300;
            padding: 0 20px 16px 0;
            line-height: 1.5;
            font-size: 17px; // Ajustado tamaño de fuente para pantallas más pequeñas
            color: $grey-text;
          }
        }

        .icon {
          width: 60px; // Ajustado tamaño del ícono para pantallas más pequeñas
          height: 60px;
          border-radius: 50%;
          border: $gold 1px solid;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            color: $gold;
            width: 22px; // Ajustado tamaño del SVG para pantallas más pequeñas
            height: 23px;
            font-weight: bolder;
          }
        }
      }
    }

    .right {
      width: 50%; // Establece la anchura para pantallas medianas y grandes
      // margin-left: 40px; // Margen izquierdo para pantallas medianas y grandes
      display: flex;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%; // Ajusta el tamaño de la imagen a la anchura del contenedor
        height: auto; // Mantiene la relación de aspecto de la imagen
        max-width: 400px; // Establece un ancho máximo para pantallas grandes
        max-height: 600px; // Establece una altura máxima para pantallas grandes
      }
    }
  }
}

// Media query para pantallas más grandes
@media (max-width: 768px) {
  .platform_top {
    .platform_container {
      flex-direction: column;
      .right {
        width: 100%;
        .platform_item {
          width: 90%;
          .text_container {
            width: 90%;
          }
        }
      }
      .left {
        width: 100%;
      }
    }
  }
}

@media (max-width: 425px) {
  .platform_top {
    .platform_container {
      flex-direction: column;
      .right {
        width: 100%;
        .platform_item {
          width: 100%;
          .text_container {
            width: 90%;
          }
        }
      }
      .left {
        padding-left: 25px;
      }
    }
  }
}
