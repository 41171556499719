@import '../../App.scss';

.benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .benefits_container {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 15px;
    h2 {
      overflow: hidden;
      font-size: 30px;
      text-align: center;
      border-bottom: $yellow 3px solid;
      padding-bottom: 25px;
      margin-bottom: 50px;
      color: $grey-text;
      font-weight: 500;
      padding-top: 50px;
    }
  }

  .cards_container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-bottom: 100px;
    flex-wrap: wrap;
    justify-content: center;

    .card {
      max-width: 360px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .card_title {
        background-color: $dark-blue;
        width: 100%;
        h4 {
          font-size: 20px;
          color: $white;
          padding: 15px;
          text-align: center;
          font-weight: 400;
        }
      }

      .card_bottom {
        width: 100%;

        .text_container {
          padding: 20px 0;
          background-color: $light-grey;
          border-radius: 0 0 10px 10px;
          margin-bottom: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            text-align: center;
            width: 280px;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.5;
            color: $dark-blue;
          }

          b {
            font-size: 60px;
            font-weight: 400;
            color: black;
            padding-left: 20px;
            padding-right: 20px;
          }

          p {
            margin: 15px;
            font-size: 17px;
            font-weight: 300;
            line-height: 2.5;
            text-align: center;
            color: $grey-text;
          }
        }
      }
    }
  }
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .benefits .benefits_container h2 {
    // font-size: 28px;
  }

  .benefits .cards_container .card {
    max-width: 320px;
  }
}

@media (max-width: 992px) {
  .benefits .benefits_container h2 {
    // font-size: 26px;
  }

  .benefits .cards_container .card {
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .benefits .benefits_container h2 {
    // font-size: 24px;
    padding-top: 100px;
  }

  .benefits .cards_container {
    flex-direction: column;
    gap: 20px;
  }

  .benefits .cards_container .card {
    max-width: 350px;
  }

  .benefits .cards_container .card .card_title h4 {
    // font-size: 20px;
  }

  .benefits .cards_container .card .card_bottom .text_container span {
    width: 100%;
    // font-size: 16px;
  }

  .benefits .cards_container .card .card_bottom .text_container b {
    // font-size: 50px;
  }

  .benefits .cards_container .card .card_bottom .text_container p {
    margin: 10px 15px;
    // font-size: 14px;
  }
}

@media (max-width: 480px) {
  .benefits .benefits_container {
    height: auto;
  }
  .benefits .benefits_container h2 {
    // font-size: 22px;
    padding-top: 50px;
  }

  .benefits .cards_container {
    flex-direction: column;
    gap: 15px;
  }

  .benefits .cards_container .card {
    max-width: 280px;
  }

  .benefits .cards_container .card .card_title h4 {
    // font-size: 18px;
  }

  .benefits .cards_container .card .card_bottom .text_container span {
    width: 100%;
    // font-size: 14px;
  }

  .benefits .cards_container .card .card_bottom .text_container b {
    // font-size: 40px;
  }

  .benefits .cards_container .card .card_bottom .text_container p {
    margin: 5px 10px;
    // font-size: 12px;
  }
}
