@import '../../App.scss';

nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $dark-blue;
  height: 80px;
  min-width: max-content;
  transition: background-color 0.3s ease;
  justify-content: center;
  &.scrolled {
    background-color: $navbar-degrade;
    .nav_container {
      .menu {
        background-color: transparent;
      }
      ul {
        &.open {
          background-color: $navbar-degrade;
        }
      }
    }
  }
  .nav_container {
    width: 1100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo {
      height: 60px;
      width: 158px;
    }

    .menu {
      display: none;
      position: relative;
      width: 2.5rem;
      height: 2rem;
      border: 2px solid $gold;
      border-radius: 0.4rem;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 5px;
      background-color: $dark-blue;
      svg {
        display: block;
        color: $gold;
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      list-style: none;
      padding: 0;
      margin: 0;
      // width: 100%;
      justify-content: flex-end;

      .dropbtn {
	  //background-color: #04AA6D;
	  color: inherit;
	  color: $white;
          cursor: pointer;
          font-size: 16px;
	  font-weight: 450;
          padding: 2px;
	  //padding: 16px;
	  //font-size: 16px;
	  //border: none;
      }

      .langhor { display: none;}
      
      .dropdown {
	  position: relative;
	  display: inline-block;
      }

      .dropdown-content {
	  display: none;
	  position: absolute;
	  background-color: $dark-blue;
	  min-width: 100%;
	  justify-content: center;
	  //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	  z-index: 1;
	  border-radius: 3px;
      }

      .dropdown-content lib {
	  //color: black;
	  //padding: 12px 16px;
	  //text-decoration: none;
	  display: block;
	  align-items: center;
      }

      .dropdown-content a:hover {background-color: $dark-blue; color:$gold;}

      .dropdown:hover .dropdown-content {display: block;}

      .dropdown:hover .dropbtn {color:$gold ;}
      
      button {
	  border: none;
	  background-color: transparent;
	  padding: 2px;
	  display: block;
	  align-items: center;
	  justify-content: center;
      }
      button:disabled {
	      display: none;
	  }
      lib {
        color: $white;
        cursor: pointer;
        font-size: 16px;
	font-weight: 450;
        padding: 2px;
	align-items: center;
	
        &:hover {
          color: $gold;
        }
        &.open_sans {
          font-weight: 550;
        }
	
        a {
          color: $white;
          text-decoration: none;
          &:hover {
            color: $gold;
          }
          &.open_sans {
            font-weight: 450;
          }
        }
      }
      li {
        color: $white;
        cursor: pointer;
        font-size: 16px;
        padding: 15px;
        &:hover {
          color: $gold;
        }
        &.open_sans {
          font-weight: 550;
        }
	
        a {
          color: $white;
          text-decoration: none;
          &:hover {
            color: $gold;
          }
          &.open_sans {
            font-weight: 450;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  nav {
    padding: 1rem 4rem;
    width: 100%;
    .nav_container {
	width: 100%;
	.button{
	    margin-bottom: 1rem;
	}
    }
  }
}
@media (max-width: 1050px) {
  nav {
    &.scrolled {
      ul {
        &.open {
          background-color: $navbar-degrade;
        }
      }
    }
    
    .nav_container {
      width: 100%;
      align-items: center;
      .menu {
        display: flex;
      }

      
      ul {
        display: none;
        flex-direction: column;
        width: 100%;
        padding: 0;
        &.open {
          display: flex;
          transition: all 0.4s;
          position: absolute;
          top: 100%; // Establece top al 100% para posicionarse debajo del navbar
          left: 0; // Alinea a la izquierda
          width: 100%; // Ocupa todo el ancho
          background-color: $dark-blue; // Añade color de fondo para distinguir
          z-index: 1000;
          border-top: 1px solid $gold;
        }
	.langhor { display: inline;}
	.dropdown { display: none;}
	
	button {
	    display: inline;
	    margin-bottom: 1rem;
	}
        li {
          width: 100%;
          text-align: center;
          padding: 1rem;
          a {
            margin: 0;
          }
        }
	lib {
          width: 100%;
          text-align: center;
          padding: 1rem;
          a {
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
    nav {
    padding: 0.5rem 2rem;
    .nav_container {
      .logo {
        height: 50px;
        width: auto;
      }

      .menu {
        width: 2.5rem;
        height: 2rem;
      }

      ul {
        &.open {
          height: auto; // Ajusta la altura automáticamente según el contenido
          padding: 1rem;
          top: 100%; // Asegura que el menú se abra justo debajo del navbar
          left: 0;
          background-color: $dark-blue;
          z-index: 1000;
        }
	button {
	    display: inline;
	    margin-bottom: 1rem;
	}
        li {
          font-size: 14px;
        }
	lib {
	    font-size: 14px;
	    padding: 1rem;
        }
      }
    }
  }
}
