@import '../../App.scss';
.header {
  width: 100%;
  background-image: url('../../images/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  margin-top: 80px;
  .header_container {
    width: 100%;
    height: 100%;
    background-color: $dark-blue-degrade;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header_text_container {
      display: flex;
      flex-direction: column;
      width: 1100px;
      height: 100%;
      justify-content: center;
      margin-bottom: 50px;
      .header_text {
        overflow: hidden;
        width: 50%;
        h1 {
          font-size: 40px;
          color: $white;
          font-weight: 400;
          margin-bottom: 20px;
          // -webkit-text-stroke: 0.7px $text-black;
          margin-top: 50px;
        }
        p {
	    font-size: 16px;
          line-height: 1.5;
          margin-bottom: 20px;
          font-weight: 325;
          text-align: start;
          padding-bottom: 16px;
          color: $white;
          -webkit-text-stroke: 0.1px $text-black;
          width: 100%;
        }
        button {
          background-color: $gold;
          margin-right: 10px;
          line-height: 20px;
          padding: 15px 35px;
          height: 50px;
          border: 2px solid $gold;
          border-radius: 100px;
          a {
            font-weight: 700;
            font-size: 17px;
            text-decoration: none;
            color: $white;
          }
          cursor: pointer;
          &:hover {
            background-color: transparent;
            transition: all 0.4s;
            a {
              color: $gold;
            }
          }
          &.open_sans {
            font-weight: 600;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .header {
    .header_container {
      width: 100%;
      .header_text_container {
        width: 100%;
        padding: 1rem 4rem;
      }
    }
  }
}
@media (max-width: 1050px) {
  .header {
    .header_container {
      .header_text_container {
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    background-attachment: scroll;
    .header_container {
      .header_text_container {
        .header_text {
          width: 80%;
          button {
            font-size: 0.9em;
            a {
              padding: 10px;
              text-align: center;
            } // Ajusta el tamaño del texto del botón
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .header {
    .header_container {
      .header_text_container {
        padding: 3rem 2rem;
        .header_text {
          h1 {
            font-size: 32px; // Tamaño de fuente aún más pequeño
            margin-top: 0;
          }

          p {
            font-size: 18px; // Tamaño de fuente aún más pequeño
            line-height: 1.5;
            width: 100%;
            margin: 0;
          }

          button {
            width: 100%;
            font-weight: 400;
            padding: 0;
          }
        }
      }
    }
  }
}
