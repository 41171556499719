@import '../../App.scss';

.platform_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  h2 {
    font-size: 30px; // Ajusta el tamaño del texto
    text-align: center;
    border-bottom: $yellow 3px solid;
    padding-bottom: 25px;
    margin-bottom: 50px;
    color: $grey-text;
    font-weight: 500;
    padding-top: 150px;
  }

  .screenshots {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
}
