@import '../../App.scss';


.simulator {
  .simulator_container {
    display: flex;
    flex-direction: column;
    align-items: center;


 

    
    h4 {
      margin: 50px 50px 0 50px;
      font-size: 25px;
      max-width: 750px;
      text-align: center;
      color: $grey-text;

      @media (max-width: 480px) {
        margin: 25px 20px 20px 20px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-top: 40px;
      margin-bottom: 40px;

      @media (max-width: 768px) {
        gap: 10px;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      @media (max-width: 480px) {
        gap: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .form_item {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 250px;
        margin-bottom: 10px;

        .error {
          color: red;
          font-weight: 400;
          font-size: 14px;
        }

	.autocomplete {
	    /*the container must be positioned relative:*/
	    width: 20px;
	    position: relative;
	    display: inline-block;
	}
	.autocomplete-items {
	    position: absolute;
	    border: 1px solid #d4d4d4;
	    border-bottom: none;
	    border-top: none;
	    z-index: 99;
	    /*position the autocomplete items to be the same width as the container:*/
	    top: 100%;
	    left: 0;
	    right: 0;
	}
	.autocomplete-items div {
	    padding: 10px;
	    cursor: pointer;
	    background-color: #fff;
	    border-bottom: 1px solid #d4d4d4;
	}
	.autocomplete-items div:hover {
	    /*when hovering an item:*/
	    background-color: #e9e9e9;
	}
	.autocomplete-active {
	    /*when navigating through the items using the arrow keys:*/
	    background-color: DodgerBlue !important;
	    color: #ffffff;
	}
        p {
          color: $grey-text;
          font-size: 15px;
          font-weight: 400;

	     .ttip {
	position: relative;
	display: inline-block;
	/*  border-bottom: 1px dotted black;*/
    }
	     
    .ttip .ttiptext {
	visibility: hidden;
	width: 105px;
	font-size: 12px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	/* Position the tooltip */
	position: absolute;
	z-index: 1;
	 top: 0px;
	 left: -105px;
    }
    
    .ttip:hover .ttiptext {
    visibility: visible;
    }
        }

        input {
          padding: 10px;
          border-radius: 0;
          font-size: 15px;
          font-weight: 300;
          border: 1px solid $grey-text;
          color: $grey-text;

          &:focus {
            outline: none;
            box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -webkit-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -moz-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
          }

          &::placeholder {
            color: $grey-text;
            font-weight: 400;
          }
        }

        select {
          padding: 10px;
          border-radius: 0;
          font-size: 15px;
          font-weight: 200;
          border: 1px solid $grey-text;
          color: $grey-text;
          font-weight: 400;

          &:focus {
            outline: none;
            box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -webkit-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
            -moz-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
          }
        }
      }

      button {
        background-color: $gold;
        color: $white;
        font-weight: 700;
        font-size: 17px;
        margin: 20px;
        line-height: 20px;
        padding: 15px 35px;
        height: 50px;
        border: 2px solid $gold;
        border-radius: 100px;
        cursor: pointer;

        &:hover {
          background-color: transparent;
          transition: all 0.4s;
          color: $gold;
        }
      }
    }
  }
}
