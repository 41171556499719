@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Puritan:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.open_sans {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}


$dark-blue: #203864;
$dark-blue-degrade: #2038649a;
$navbar-degrade: #203864d0;
$white: #ffffff;
$gold: #af9f7d;
$gold-degrade: #af9f7d8f;
$grey-text: #777777;
$grey-background: #e6e6e6;
$text-black: #202020;
$yellow: #f9d137;
$grey-border: #dadada;
$red: #f33030;
$light-grey: #f3f3f3;
$light-blue: #3eb0f7;
$dark-light-blue: #1e96e0;
$dark-ocean-blue: #44546a;

// @font-face {
//   font-family: 'Fontawsome';
//   src: url('./fonts/fontawesome-webfont.eot?v=4.2.0');
//   src: url('./fonts/fontawesome-webfont.eot?#iefix&v=4.2.0')
//       format('embedded-opentype'),
//     url('./fonts/fontawesome-webfont.woff?v=4.2.0') format('woff'),
//     url('./fonts/fontawesome-webfont.ttf?v=4.2.0') format('truetype'),
//     url('./fonts/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular')
//       format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Glyphicons Haflings';
//   src: url('./fonts/glyphicons-halflings-regular.eot') format('eot');
//   src: url('./fonts/glyphicons-halflings-regular.eot?#iefix')
//       format('embedded-opentype'),
//     url('./fonts/glyphicons-halflings-regular.ttf') format('truetype'),
//     url('./fonts/glyphicons-halflings-regular.woff') format('woff')
//       url('./fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular')
//       format('svg');

//   /* Add additional font formats if necessary */
//   /* Specify the correct path to the font files */
// }
