.slider-container {
  position: relative;
  width: 90%;
}

.slider-container .slick-list {
  margin: 0; /* Centra el contenido si es necesario */
  padding: 0;
}

.slider-container .slick-list .slick-track {
  display: flex; /* Asegúrate de que los elementos se alineen correctamente */
}

.slider-container .slick-list .slick-track .slick-slide div img {
/*    width: 243px;
    height: 528px; */ /*Esto es afectado por el padding, (hay que restarle el padding al width y recalcular altura)*/
/*    height: 485px;*/
    /*  width: auto;*/
    width: auto; /* al final funcionó poner los dos en auto*/
    height: auto;
  border: 3px solid #dadada;
  border-radius: 4px;
}

.slider-container .slick-list .slick-track .slick-slide div img:hover {
  border: #f33030 3px solid;
  transition: all 1.4s;
}

.slider-container .slick-list .slick-track .slick-slide div {
    display: flex;
/*  height: 100%;*/
  justify-content: center;
  padding: 10px;
}

@media (max-width: 1400px) {
  .slider-container .slick-list .slick-track .slick-slide div img {
    width: 200px;
    height: auto;
  }
}
@media (max-width: 1200px) {
  .slider-container .slick-list .slick-track .slick-slide div img {
    width: 200px;
    height: auto;
  }
}
@media (max-width: 768px) {
  .slider-container .slick-list .slick-track .slick-slide div img {
    width: 200px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .slider-container .slick-list .slick-track .slick-slide div img {
    width: 100%;
    height: auto;
    max-width: 300px;
  }
}

/* Estilo para los puntos de navegación */
.slick-dots {
  position: absolute;
  bottom: 10px; /* Ajusta la posición de los dots según sea necesario */
  width: 100%; /* Asegúrate de que los dots ocupen todo el ancho del contenedor */
  display: flex;
  justify-content: center; /* Centra los dots horizontalmente */
  padding: 0;
  margin: 0;
  z-index: 10; /* Asegúrate de que los dots estén por encima de otros elementos */
  background: rgba(
    255,
    255,
    255,
    0.5
  ); /* Fondo temporal para verificar la posición */
}

.slick-dots li {
  margin: 0 5px; /* Espacio entre los puntos de navegación */
}
