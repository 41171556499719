// @import '../../App.scss';

// .contact {
//   background-color: $white;
//   display: flex;
//   align-items: center;
//   width: 100%;
//   justify-content: center;
//   height: 60vh;
//   .contact_container {
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     gap: 50px;
//     .left {
//       width: 60%;
//       margin-left: 70px;
//       form {
//         display: flex;
//         flex-direction: column;
//         width: 100%;
//         gap: 10px;
//         input {
//           margin-bottom: 15px;
//           padding: 20px;
//           border-radius: 0;
//           font-size: 16px;
//           font-weight: 200;
//           border: 1px solid $grey-background;
//           &:focus {
//             outline: none;
//             box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
//             -webkit-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
//             -moz-box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
//           }
//         }
//         // input {
//         //   border-radius: 0;
//         //   border: solid 1px #dadada;
//         //   background-color: #fff;
//         //   color: #333;
//         //   font-size: 1.2em;
//         //   height: 55px;
//         //   padding-left: 20px;
//         // }
//         textarea {
//           margin-bottom: 15px;
//           padding: 20px;
//           border-radius: 0;
//           font-size: 16px;
//           font-weight: 200;
//           border: 1px solid $grey-background;
//           color: grey;
//           &:focus {
//             outline: none;
//             box-shadow: 0px 0px 6px -1px rgba(43, 43, 43, 0.75);
//             -webkit-box-shadow: 0px 0px 6px -1px rgba(43, 43, 43, 0.75);
//             -moz-box-shadow: 0px 0px 6px -1px rgba(43, 43, 43, 0.75);
//           }
//         }
//         button {
//           background-color: $light-blue;
//           padding: 20px;
//           color: $white;
//           border: none;
//           font-weight: 300;
//           font-size: 18px;
//           &:hover {
//             background-color: $dark-light-blue;
//             transition: all 0.4s;
//           }
//         }
//       }
//     }
//     .right {
//       width: 40%;
//       .text_container {
//         display: flex;
//         flex-direction: column;
//         width: 80%;
//         h2 {
//           font-size: 30px;
//           font-weight: 200;
//           margin-bottom: 20px;
//         }
//         span {
//           line-height: 1.8;
//           font-size: 16px;
//           font-weight: 200;
//           border-block-start: 20px;
//         }
//         p {
//           color: $light-blue;
//           font-size: 16px;
//           font-weight: 500;
//           margin-top: 15px;
//         }
//       }
//     }
//   }
// }

@import '../../App.scss';

.contact {
  background-color: $white;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  min-height: 60vh;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    height: auto;
    padding: 20px 0;
  }

  .contact_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 50px;
    margin: 0 100px;

    @media (max-width: 1024px) {
      gap: 30px;
    }

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 20px;
      margin: 25px;
    }

    .left {
      width: 60%;
      margin-left: 70px;

      @media (max-width: 1024px) {
        width: 70%;
        margin-left: 40px;
      }

      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }

      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;

        input,
        textarea {
          margin-bottom: 15px;
          padding: 20px;
          border-radius: 0;
          font-size: 17px;
          font-weight: 200;
          border: 1px solid $grey-background;
          resize: none;

          @media (max-width: 768px) {
            // font-size: 14px;
            padding: 15px;
          }

          &:focus {
            outline: none;
            box-shadow: 0px 0px 3px -1px rgba(43, 43, 43, 0.75);
          }
        }

        button {
          background-color: $light-blue;
          padding: 20px;
          color: $white;
          border: none;
          font-weight: 500;
          font-size: 18px;

          @media (max-width: 768px) {
            // font-size: 16px;
            padding: 15px;
          }

          &:hover {
            background-color: $dark-light-blue;
            transition: all 0.4s;
          }
        }
      }
    }

    .right {
      width: 40%;

      @media (max-width: 1024px) {
        width: 30%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      .text_container {
        display: flex;
        flex-direction: column;
        width: 80%;

        @media (max-width: 768px) {
          width: 100%;
        }

        h2 {
          font-size: 30px;
          font-weight: 400;
          margin-bottom: 20px;

          // @media (max-width: 768px) {
          //   font-size: 24px;
          // }
        }

        span {
          line-height: 1.8;
          font-size: 17px;
          font-weight: 200;
          border-block-start: 20px;

          // @media (max-width: 768px) {
          //   font-size: 14px;
          // }
        }

        p {
          color: $light-blue;
          font-size: 17px;
          font-weight: 500;
          margin-top: 15px;

          // @media (max-width: 768px) {
          //   font-size: 14px;
          // }
        }
      }
    }
  }
}
