// @import '../../App.scss';

// .project {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .project_container {
//     display: flex;
//     flex-direction: row;
//     width: 1100px;
//     margin-top: 50px;
//     margin-bottom: 50px;
//     .left {
//       img {
//         height: 555px;
//         width: 555px;
//       }
//     }
//     .right {
//       display: flex;
//       flex-direction: column;
//       height: 555px;
//       margin-top: 50px;
//       h2 {
//         color: $grey-text;
//         font-weight: 100;
//         font-size: 35px;
//       }
//       p {
//         color: $grey-text;
//         font-weight: 100;
//         margin-top: 15px;
//         font-size: 16px;
//         line-height: 1.8;
//         margin-bottom: 20px;
//       }
//       button {
//         background-color: transparent;
//         color: $gold;
//         font-weight: 700;
//         font-size: 17px;
//         margin-right: 10px;
//         line-height: 20px;
//         padding: 15px 35px;
//         height: 50px;
//         width: 250px;
//         border: 2px solid $gold;
//         border-radius: 100px;
//         cursor: pointer;
//         &:hover {
//           background-color: $gold;
//           color: $white;
//           transition: all 0.4s;
//         }
//       }//     }
//   }
// }

@import '../../App.scss';

.project {
  display: flex;
  align-items: center;
  justify-content: center;


  *.unselectable {
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -o-user-select: none;
   user-select: none;
}

  .project_container {
    display: flex;
    flex-direction: row;
    width: 1100px;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: hidden;
    justify-content: space-around;
    .left {
	height: 555px;
	width: 555px;
	margin-right: 0px;
    }
    .parent {
    width: 555px;
    height: 555px;
    display: flex;
    flex-direction: column;
    }
    
    .subparent{
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
    }
    .subparent_bot{
	display: flex;
	flex-direction: column;
    }
    .icon{
	width: auto;
	height: 66px;
    }
    .icon_name{
        font-size: 9pt;
	font-weight: 600;
    }
    .contain_icons{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	flex-direction: column;
    }
    img{
	max-height: 100%;
    }
    
    
    .treble {
    width: 155px;
    height: 389px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    }



.element {
    border-radius: 21px;
    width: 100%;
    height: 104px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: 0.4s;

}
    .element:hover {
	transform: scale(1.13);
	filter: brightness(88%);
    }

.base {
    width: 100%;
    height: 23px;
    justify-content: center;
    display: flex;
}

.logobase {
    width: 100%; 
    height: 136px;
    display: flex;
    flex-direction:column;
    justify-content: space-around;
}
.logoim {
    width:auto;
    max-height: 75%;
    justify-content:center;
    display:flex;
    flex-direction:row;
    margin-top:1%;
}
.slogan {
    width: 100%;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15pt;
    font-weight: 500;
    color: #A6A6A6;
}


/* color classes:*/
.lefttop {	background-color: #AABFE4;}
.leftmiddle { 	background-color: #CAD8EE;}
.leftbottom { 	background-color: #E8EDF8;}
.lefttopcol {	color: #525760;}
.leftmiddlecol { 	color:#7F7F7F ;}


.righttop{background-color: #8FAADC;}
.rightmiddle{background-color: #2F5597;}
.rightbottom{background-color: #203864;}
.righttopcol{color:#4B5566 ;}
.rightmiddlecol{color:#D9D9D9 ;}
.rightbottomcol{color: #FFFFFF;}

    

    .right {
      display: flex;
      flex-direction: column;
      height: 555px;
      margin-top: 50px;

      h2 {
        color: $grey-text;
        font-weight: 400;
        font-size: 32px;
        line-height: 1.5;
      }

      p {
        color: $grey-text;
        font-weight: 300;
        margin-top: 15px;
        font-size: 16px;
        line-height: 1.8;
        margin-bottom: 20px;
      }

      button {
        background-color: transparent;
        color: $gold;
        margin-right: 10px;
        line-height: 20px;
        padding: 15px 15px;
        height: 50px;
        width: 250px;
        border: 2px solid $gold;
        border-radius: 100px;
        cursor: pointer;
        a {
          font-weight: 700;
          font-size: 17px;
          text-decoration: none;
          color: $gold;
        }
        &.open_sans {
          font-weight: 1000;
          font-size: 17px;
        }

        &:hover {
          background-color: $gold;
          transition: all 0.4s;
          a {
            color: $white;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .project_container {
      width: 90%;
      margin-top: 40px;
      margin-bottom: 40px;


      // .right {
      //   h2 {
      //     font-size: 30px;
      //   }

      //   p {
      //     font-size: 14px;
      //   }
      // }
    }
  }

  @media (max-width: 768px) {
    .project_container {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 0 25px;

      .subparent{
	  justify-content: space-around;
      }
     
      .right {
        height: auto;
        margin-top: 20px;
        align-items: center;
        text-align: center;

        // h2 {
        //   // font-size: 25px;
        // }

        // p {
        //   // font-size: 13px;
        // }

        // button {
        //   font-size: 15px;
        //   padding: 12px 30px;
        //   height: 45px;
        //   width: 200px;
        // }
      }
    }
  }

  @media (max-width: 480px) {
    .project_container {


	      
	         .left {
		     height: 500px;
		     width: 380px;
		     margin-right: 0px;
		 }
		 .parent {
		     width: 380px;
		     height: 500px;
		 }
		 .element {
		     border-radius: 17px;
		     width: 100%;
		     height: 85px;
		 }
		 .treble {
		     width: 146px;
		     height: 360px;
		 }
		 .icon{
			 width: auto;
			 height: 54px;
		     }
		 .icon_name{
		     font-size: 8pt;
		     font-weight: 500;
		 }
		 .slogan{
		         font-size: 13pt;
			 font-weight: 400;
		 }
		 .base{
		     height:17px;   
		 }
		 .right {
		     h2 {
			 line-height: 1.2;
			 font-weight: 300;
			 font-size: 30px;
		     }
		 }
    }
  }
}
