@import '../../App.scss';

.how {
  min-height: fit-content;
  background-color: $grey-background;

  .how_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 0;
    .how_top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70%;

      h2 {
        margin-top: 75px;
        font-weight: 400;
        font-size: 40px;
        margin-bottom: 25px;
        color: $text-black;
      }

      p {
        font-size: 18px;
        font-weight: 300;
        text-align: center;
        line-height: 1.8;
        color: $text-black;
      }
    }

    .how_bottom {
      display: flex;
      flex-direction: row;
      margin-top: 75px;
      gap: 15px;
      margin-bottom: 50px;

      .how_bottom_item {
        .icon {
          svg {
            color: $dark-blue;
            &:hover {
              color: $gold;
              transition: all 0.4s;
            }
          }
        }

        display: flex;
        flex-direction: row;
        max-width: 350px;
        padding: 0 15px;

        .icon {
          margin-right: 10px;
          svg {
            width: 50px;
            height: 50px;
          }
        }

        .text_container {
          h4 {
            color: $text-black;
            margin-bottom: 15px;
            font-weight: 400;
            font-size: 20px;
          }

          p {
            font-weight: 300;
            line-height: 1.8;
            color: $text-black;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 20px 0;

    .how_container {
      .how_top {
        width: 90%;

        // h2 {
        //   font-size: 28px;
        //   margin-top: 50px;
        // }

        // p {
        //   font-size: 16px;
        // }
      }

      .how_bottom {
        margin-top: 50px;
        .how_bottom_item {
          padding: 0;
          width: 85%;
          // flex-direction: column;
          // align-items: center;
          // text-align: center;
          margin-bottom: 15px;

          .icon {
            margin-right: 0;
            margin-bottom: 10px;

            svg {
              width: 40px;
              height: 40px;
              color: $dark-blue;
            }
          }

          .text_container {
            padding: 0 25px;
            // h4 {
            //   font-size: 18px;
            // }

            // p {
            //   font-size: 14px;
            // }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .how_container {
      .how_top {
        h2 {
          font-size: 30px;
          margin-top: 30px;
        }

        p {
          font-size: 18px;
        }
      }

      .how_bottom {
        .how_bottom_item {
          .icon {
            svg {
              width: 40px;
              height: 40px;
            }
          }

          .text_container {
            h4 {
              font-size: 20px;
            }

            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .how_container {
      .how_bottom {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}
